.static {
	display: block;
}

h3 {
	color: var(--secondary);
	font-weight: 700;
}


.vacation-specials {
	.single-unit {
		max-width: inherit;
	}
}

.inner-content-wrap {
	.banner {
		.owl-item {
			.item {
				width: 100%;
				min-height: 390px;
				background-size: cover;
				background-position: no-repeat;
				background-position: center;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				/*
				&.banner1 {
					background-image: url(RESOURCE/img/banner-excursion.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner-excursion.Xc_700x650_1_1.jpg);
					}
				}

				&.banner2 {
					background-image: url(RESOURCE/img/banner-impressum.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner-impressum.Xc_700x650_1_1.jpg);
					}
				}

				&.banner3 {
					background-image: url(RESOURCE/img/banner14.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner14.Xc_700x650_1_1.jpg);
					}
				}

				&.banner4 {
					background-image: url(RESOURCE/img/banner13.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner13.Xc_700x650_1_1.jpg);
					}
				}

				&.banner5 {
					background-image: url(RESOURCE/img/banner1.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner1.Xc_700x650_1_1.jpg);
					}
				}

				&.banner6 {
					background-image: url(RESOURCE/img/banner2.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner2.Xc_700x650_1_1.jpg);
					}
				}

				&.banner7 {
					background-image: url(RESOURCE/img/banner-vacation-dog.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner-vacation-dog.Xc_700x650_1_1.jpg);
					}
				} */

				&.banner1 {
					background-image: url(RESOURCE/img/static-banner-haus.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/static-banner-haus.Xc_900x370_1_1.jpg);
					}
				}

				&.banner2 {
					background-image: url(RESOURCE/img/static-banner-beach.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/static-banner-beach.Xc_900x370_1_1.jpg);
					}
				}

				&.banner3 {
					background-image: url(RESOURCE/img/static-banner-door.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/static-banner-door.Xc_900x370_1_1.jpg);
					}
				}

				&.banner4 {
					background-image: url(RESOURCE/img/static-banner-sunrise.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/static-banner-sunrise.Xc_900x370_1_1.jpg);
					}
				}

				&.banner5 {
					background-image: url(RESOURCE/img/static-banner-prerow.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/static-banner-prerow.Xc_900x370_1_1.jpg);
					}
				}

				&.banner6 {
					background-image: url(RESOURCE/img/static-banner-segeln.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/static-banner-segeln.Xc_900x370_1_1.jpg);
					}
				}

				&.banner7 {
					background-image: url(RESOURCE/img/static-banner-haeuser.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner-vacation-dog.Xc_900x370_1_1.jpg);
					}
				}
			}
		}
	}
}

.unit-srow {
	max-width: 860px;
	margin: 30px auto 10px !important;

	@media (min-width: 993px) {
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
		margin-left: 15px;
		padding: 0;
		box-shadow: 0 0 5px rgba(0, 0, 0, .18);
		border-radius: 5px;
		margin-bottom: 35px;
		border: 2px solid #d6d6d6;

		.flex-align-center {
			padding-bottom: 14px;
			border-bottom: 1px solid #f2f2f2;
			margin-bottom: 14px;
		}

		.unit-distance {
			padding: 0 15px !important;
			font-weight: bold !important;
			right: 0 !important;
			top: 0 !important;
			font-size: 12px !important;
			line-height: 30px !important;
			color: rgba(0, 0, 0, .7) !important;
			background-color: #e6f2f7 !important;
			border-radius: 15px !important;
			position: relative !important;
		}
	}

	.unit-img {
		@media (min-width:993px) {

			position: relative;
			left: -16px;
			top: -15px;
		}
	}



	.unit-cont-bl {
		@media (min-width:993px) {
			margin: 0 !important;
			top: 0 !important;
			box-shadow: none !important;
			width: 60%;
		}
	}

	.unit-price-rat {
		@media (min-width:993px) {
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
		}
	}

}

.inner-banner {
	background-image: url(RESOURCE/img/banner5.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 390px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
	}

	&.region {
		background-image: url(RESOURCE/img/banner-region.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-region.Xc_700x650_1_1.jpg);
		}
	}

	&.vacation-family {
		background-image: url(RESOURCE/img/banner-vacation-family.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-vacation-family.Xc_700x650_1_1.jpg);
		}
	}

	&.excursion {
		background-image: url(RESOURCE/img/banner-excursion.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-excursion.Xc_700x650_1_1.jpg);
		}
	}

	&.vacation2 {
		background-image: url(RESOURCE/img/banner-vacation2.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-vacation2.Xc_700x650_1_1.jpg);
		}
	}

	&.vacation-dog {
		background-image: url(RESOURCE/img/banner-vacation-dog.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-vacation-dog.Xc_700x650_1_1.jpg);
		}
	}

	&.vacation-friends {
		background-image: url(RESOURCE/img/banner-vacation-friends.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-vacation-friends.Xc_700x650_1_1.jpg);
		}
	}

	&.vacation-special {
		background-image: url(RESOURCE/img/banner-vacation-special.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-vacation-special.Xc_700x650_1_1.jpg);
		}
	}

	&.impressum {
		background-image: url(RESOURCE/img/banner-impressum.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-impressum.Xc_700x650_1_1.jpg);
		}
	}

	&.faq {
		background-image: url(RESOURCE/img/banner-faq.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-faq.Xc_700x650_1_1.jpg);
		}
	}

	&.travel {
		background-image: url(RESOURCE/img/banner-travel.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-travel.Xc_700x650_1_1.jpg);
		}
	}

	&.online {
		background-image: url(RESOURCE/img/banner-online.jpg);

		@media (max-width:992px) {
			background-image: url(RESOURCE/img/banner-online.Xc_700x650_1_1.jpg);
		}
	}




	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}
	}
}

.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		border: 1px solid #e8e8e8;

		.team-dt {

			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}

.list-style {
	margin-left: 30px;
	font-size: 16px;

	@media (max-width: 992px) {
		font-size: 14px;
	}

}



.contact-page {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		text-transform: uppercase;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
	}

	label {
		color: var(--black);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	.checkbox {
		label {
			text-transform: inherit;
			font-weight: normal;
		}

		a {
			color: var(--black);
			font-size: 14px;

		}
	}

	.btn-go {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		font-size: 20px;
	}
}