.main-footer {
	background-color: var(--secondary);

	padding-top: 80px;
	position: relative;

	.newsletter-post-bl {
		top: -70px;
		position: absolute;
		z-index: 5;
		width: 100%;
		padding: 0 20px;

		.contact-form {
			margin-bottom: 0;

			p:last-child {
				margin-bottom: 0;
			}

			p,
			.heading1 {
				color: var(--secondary);
			}
		}
	}




	.footer-cont {
		@media (max-width:992px) {
			padding-bottom: 0;
		}
	}

	.newslet-bl {
		background-color: var(--d-blue);
		padding: 15px;
		border-radius: 10px;
		max-width: 1210px;
		margin: 0 auto;
		text-align: center;

		@media (max-width:992px) {
			padding: 16px 20px 10px;
		}

		h4 {
			color: var(--white);
			font-size: 16px;
			margin-bottom: 25px;
		}

		h2 {
			color: var(--white);
			text-transform: uppercase;
			margin-bottom: 40px;
			font-size: 21px;

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}

		.widt-cont-bl {
			background-color: #01242f;
			color: var(--white);
			border-radius: 30px;
			border: 4px solid #01242f;
			padding-left: 15px;
			display: flex;
			max-width: 420px;
			margin: 0 auto;

			@media (max-width:992px) {
				border: none;
			}

			.form-control {
				border: none;
				background-color: transparent;
				font-size: 16px;
				font-family: var(--d-font);
				height: inherit;
				padding: 10px 20px;
				color: var(--white);

				@media (max-width:992px) {
					padding: 7px 0;
					font-size: 13px;
				}
			}

			.btn-secondary {
				padding: 12px 40px;
				border-radius: 30px;
				color: var(--white);

				@media (max-width:992px) {
					padding: 7px 20px;
					font-size: 14px;
				}
			}
		}
	}

	.social-footer {
		margin-bottom: 30px;
		max-width: 336px;
		text-align: center;

		@media (max-width:992px) {
			text-align: center;
		}

		a {
			width: 40px;
			height: 40px;
			display: inline-block;
			margin: 0 5px;
			text-align: center;
			border-radius: 50%;
			font-size: 18px;
			line-height: 30px;
			text-decoration: none;
			color: var(--secondary);
			line-height: 42px;
			background-color: var(--white);

			&:hover {
				background-color: var(--primary);
				color: var(--white);
			}

			&:last-child {
				margin-right: 0;
			}

		}
	}

	.ft-logo {
		margin-bottom: 35px;

		@media (max-width:992px) {
			text-align: center;
		}
	}

	.widget-bl {
		@media (max-width:992px) {
			margin-bottom: 20px;
			border-bottom: 1px solid #078dcc;
			padding-bottom: 25px;
			margin-bottom: 25px;
		}

		.widgt-title {
			font-family: var(--d-font);
			color: var(--d-blue2);
			margin-bottom: 40px;
			font-weight: bold;
			display: block;

			a {
				color: var(--white);
			}

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}
	}

	.blue-li {
		li {
			list-style: none;
			margin-bottom: 10px;

			a {
				color: var(--white);
				font-size: 16px;
				font-family: var(--d-font2);

				&:hover {
					color: var(--primary);
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.icon-white-li {


		@media (min-width:993px) {
			display: flex;
			flex-wrap: wrap;
		}

		li {
			list-style: none;
			margin-bottom: 22px;
			position: relative;
			padding-left: 45px;
			color: var(--white);

			&:last-child {
				margin-bottom: 0;
			}

			i {
				font-size: 24px;
				opacity: .5;
				position: absolute;
				left: 0;
				top: 0px;
				color: var(--white);

				&.fa-phone-alt {
					top: 2px;
				}
			}

			a {
				color: var(--white);
				font-size: 18px;
				font-weight: bold;
				margin: 0;

				&:hover {
					color: var(--primary);
				}
			}

			h4 {
				margin: 0;
			}

			span {
				font-size: 14px;
				opacity: .5;
				display: block;

			}
		}
	}

	.copyright {
		padding-bottom: 20px;
		color: var(--grey);
		opacity: .5;
		font-size: 16px;

		a {
			margin-left: 8px;
			padding-left: 12px;
			color: var(--grey);
			border-left: 2px solid var(--grey);
		}



		@media (max-width:992px) {
			text-align: center;
		}
	}
}