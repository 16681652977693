:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/bg-menu-blue2.jpg);
	--navbarbreak: 768px;
	--primary: #ABC43B;
	--secondary: #296E91;
	--orange: #EB8902;
	--black: #606060;
	--d-font: 'Arial', Verdana, sans-serif;
	--d-font2: 'lato', sans-serif;
	--white: #fff;
	--d-blue: #033848;
	--d-blue2: #03364d;
	--h1-font: 'lato', sans-serif;
	--grey: #e5e5e5;
	--d-grey: #727272;
	--blue3: #025a84;
	--blue4: #052444;
	--li-blue: #daf0fb;
	--grey2: #7b7b7b;
	--red: #f55454;
	--grey3: #999999;
	--grey4: #f9f9f9;
	--beige-bg: #F6F4EC;
	--cal_not_available: #cd7898;
}