@import "setup.css";


.header-style2,
.header-style3 {
	.vo-nav-bar.mainHeader {
		padding: 10px 0 !important;
		border-bottom: 3px solid #f9f9f9;
		background-color: rgba(255, 255, 255, 1);


		.navbar-area {
			.logo {
				a {
					background-image: url(RESOURCE/img/logo.png) !important;
					background-size: 220px;
					width: 220px;
					height: 50px;
				}
			}

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--black);
						}
					}
				}
			}

			.fav-count {
				color: var(--primary) !important;
			}

			.navbar-toggler {
				color: var(--grey2) !important;
				top: 20px !Important;
			}
		}
	}

}

.vo-nav-bar {

	&.mainHeader {
		position: fixed;
		width: 100%;
		top: 0px;
		z-index: 50;
		display: flex;
		align-items: center;
		padding: 28px 0;
		transition: all ease-in .2s;
		background-color: rgba(255, 255, 255, 0);

		@media (max-width:992px) {
			padding: 20px 0;
		}

		.navbar-area {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;

			@media (max-width:992px) {
				padding: 0;
				margin-left: 60px;
			}

			.search-form {
				display: block;

				@media (max-width:580px) {
					display: none;
				}

			}


			.srch-btn {
				font-size: 18px;
				color: var(--primary);
				padding-top: 3px;
				padding-bottom: 0px;
				padding-right: 5px;
				padding-left: 5px;
				border: 1px solid #CCC;
				line-height: 29px;

				@media (max-width:992px) {
					margin-right: 5px;
				}
			}

			.objsearch {
				width: 160px;
			}

			.nav-right {
				display: flex;
				justify-content: flex-end;

				.fav-count {
					color: var(--white);
					position: relative;
					margin-left: 10px;
					margin-top: 5px;


					.fa {
						font-size: 24px;
					}

					.sub-count {
						-moz-border-radius: 50%;
						-webkit-border-radius: 50%;
						border-radius: 50%;
						display: block;
						position: absolute;
						top: -6px;
						left: 7px;
						width: 18px;
						height: 18px;
						background: red;
						background: var(--orange);
						color: var(--white);
						text-align: center;
						font-size: 12px;
						line-height: 18px;
					}
				}
			}
		}

		.logo {
			a {
				background-image: url(RESOURCE/img/logo.png);
				width: 336px;
				height: 77px;
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center;

				@media (max-width:1500px) {
					max-width: 165px;
					background-size: 165px;
					height: 45px;
				}

				@media (max-width:1024px) {
					max-width: 140px !important;
					background-size: 140px !important;
					height: 40px !important;
				}
			}


		}



		.topNav {
			.navbar {
				min-height: inherit;
				margin: 0;

				@media (max-width:992px) {
					position: inherit;
				}
			}

			.navbar-toggler {
				background: transparent;
				font-size: 24px;
				color: var(--white);
				padding: 0;
				border: none;
				box-shadow: none;
				display: none;

				@media (max-width:992px) {
					display: inline-block;
					position: absolute;
					left: 20px;
					top: 24px;

				}
			}



			ul {
				margin: 0;
				padding: 0px;

				&.two-col-drop {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					width: 440px;

					li {
						@media (min-width:993px) {
							width: 50% !important;
						}
					}
				}

				li {
					list-style: none;
					padding: 0px;

					@media (min-width:1491px) {
						margin: 0px 3px;
					}

					a {
						font-size: 16px;
						color: var(--white);
						display: inline-block;
						padding: 6px 10px;
						background: transparent;
						text-align: left;
						text-transform: uppercase;
						font-weight: bold;
						text-shadow: 0 0 10px rgba(0, 0, 0, .3);
						border-radius: 3px;
						font-family: var(--d-font2);
						font-style: italic;


						@media (max-width:1490px) {
							padding: 5px 10px;
							font-size: 14px;
						}

						@media (max-width:992px) {
							padding: 7px 0;

						}

						span {
							padding: 3px 5px;
							border-radius: 3px;
							background-color: var(--secondary);
							color: var(--white);
							display: inline-block;
							line-height: 13px;
							font-size: 14px;
							margin-left: 3px;
						}
					}

					&:hover {
						a {
							background-color: var(--primary);

						}

						.dropdown-menu {
							display: block;
						}
					}

					.dropdown-menu {
						min-width: 260px;
						left: 0px;
						margin: 0px;
						padding: 0;
						background-color: var(--primary);
						border: none;
						border-radius: 0 3px 3px 3px;
						margin-top: -1px;

						@media (max-width:992px) {
							ul {
								padding: 0;
							}
						}

						li {
							float: left;
							width: 100%;
							border-bottom: 1px solid rgba(255, 255, 255, .2);
							margin: 0px;
							padding: 8px 20px;

							&:last-child {
								border-bottom: none;
							}
						}

						a {
							font-size: 14px;
							display: block;
							padding: 0;

							&:hover {
								color: var(--secondary);
							}
						}
					}
				}

			}
		}
	}

	&.affix {
		padding: 10px 0 !important;
		border-bottom: 3px solid #f9f9f9;
		background-color: rgba(255, 255, 255, 1);

		.navbar-area {
			.logo {
				a {
					background-image: url(RESOURCE/img/logo.png) !important;
				}
			}

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--black);
						}
					}
				}
			}

			.fav-count {
				color: var(--primary) !important;
			}

			.navbar-toggler {
				color: var(--grey2) !important;
				top: 20px !Important;
			}
		}
	}

	@media (max-width:992px) and (min-width: 768px) {
		.navbar-collapse.collapse {
			display: none !important
		}

		.navbar-collapse.collapse.in,
		.navbar-collapse.collapsing {
			display: block !important
		}
	}


	@media screen and (max-width: 992px) {

		.topNav {

			.navbar-collapse {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 58px;
				background: var(--primary);
				padding: 0px;
				overflow: scroll !important;
				max-height: 450px;


				ul {
					magin: 0px;
					float: left;
					width: 100%;
					padding: 10px 15px;

					li {
						float: left;
						width: 100%;

						margin: 0px;

						a {
							color: #ffffff;
						}

						&:hover {
							a {
								color: #ffffff;
							}

							.dropdown-menu {
								display: none;
							}
						}

						&.open {
							.dropdown-menu {
								display: block;
								position: static;
							}
						}

						.dropdown-menu {
							position: static;
							float: none;
							width: auto;
							margin-top: 0;
							background-color: transparent;
							border: 0;
							box-shadow: none;

						}
					}
				}
			}
		}
	}
}

.mm-page {
	&.mm-slideout {
		z-index: inherit;
	}
}

@media (max-width: 992px) {

	.mobile-navigation {
		ul.mm-listview {
			li {

				&.last {
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;

					a {
						border: 1px solid var(--orangeColor);
						border-radius: 100px;
						text-transform: uppercase;
						padding: 10px 30px;
						max-width: 125px;
						font-size: 13px;
						margin: 22px 0 0 0;

						&:hover {
							background: var(--orangeColor);
							border-color: var(--orangeColor);
						}
					}
				}
			}
		}

	}

	.mm-menu_offcanvas {
		width: 100vw;
		min-width: 100vw;
		z-index: 51;
		max-width: 100vw;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
		-webkit-transform: translate3d(100vw, 0, 0);
		transform: translate3d(100vw, 0, 0);
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.navbar-nav,
	.navbar-nav>li {
		float: none !Important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.mm-menu_offcanvas {
		display: none;
	}

	.mm-wrapper_opening .mm-menu_offcanvas {
		display: block;
	}

	.mm-wrapper_opening .mm-page.mm-slideout {
		display: none;

	}

	.mm-wrapper_opened .main-footer {
		display: none;
	}

	.mm-navbar {
		background: none;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		padding-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.mm-navbar__title>span {
		color: var(--white);
		font-size: 20px;
		font-weight: bold;
	}

	.mm-panel {
		background-image: var(--mobileMenuImg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin: 0;
	}

	.mm-listitem {
		text-align: center;
		font-size: 20px;
		color: var(--white);

		a {
			span {
				padding: 0 8px 2px;
				line-height: 1;
				font-size: 14px;
				border-radius: 3px;
				background-color: var(--primary);
				position: relative;
				top: -2px;
			}
		}

		i.fal,
		i.far {
			display: none;
		}
	}

	.mm-listitem:after {
		display: none;
	}

	.mm-listitem__btn {
		border: none !important;
		position: absolute;
		right: 0;
		top: 12px;
		color: var(--white);
	}

	.mm-btn_next {
		position: absolute;
		left: 0;
		width: 100%;
		height: 30px;
		top: 6px;

		&:after {
			right: 30px;
		}
	}


	.mm-btn:after,
	.mm-btn:before {
		border-color: var(--white);
	}

	.mm-listview {
		max-height: 75vh;
		overflow: scroll;
		padding-bottom: 35px;
	}


	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
		z-index: 51;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}
}

.inner-page {
	padding: 10px 0 !important;
	border-bottom: 3px solid #f9f9f9;
	background-color: rgba(255, 255, 255, 1) !important;

	.navbar-area {
		.logo {
			a {
				background-image: url(RESOURCE/img/logo.png) !important;
				background-size: 220px;
				width: 220px;
				height: 50px;

				@media (max-width:1500px) {
					max-width: 165px;
					background-size: 165px;
					height: 45px;
				}

				@media (max-width:1024px) {
					max-width: 140px !important;
					background-size: 140px !important;
					height: 40px !important;
				}
			}

		}

		.topNav {
			ul {
				li {
					a {
						text-decoration: inherit;
						text-shadow: none;
						color: var(--black);
					}
				}
			}
		}

		.fav-count {
			color: var(--primary) !important;
		}

		.navbar-toggler {
			color: var(--grey2) !important;
			top: 20px !Important;
		}
	}

}